#sidebar {
    position: fixed;
    top: 10;
    left: 0;
    width: 160px;
    background-color: #656d75;
    height: 85vh;
    padding-top: 20px;
}

.sidebar-menu-item nav-item a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

.sidebar-menu-item:hover {
    background-color: #889199;
    cursor: default;
}

.sidebar-menu-item {
    color: #fff;
}

#content {
    padding-left: 20px;
    float: left;
    width: calc(100% - 200px);
}

.dataGrid {
    height: 100%;
}

#dataTable, .divRenderGroupsTable {
    width: 100%;
}

#dataTable td {
    font-size: .8rem;
    font-weight: 300;
    padding: 4px;
}

#dataTable .deletedUser {
    background-color: #f5e5eb;
}

#dataTable tr:hover, .dataRow:hover {
    background-color: #C8C8C9;
    cursor: default;
}

.header {
    background-color: #656d75;
    justify-content: space-between;
}

.temp {
    background-color: #ebd6d0
}

.header th, .header div {
    color: #fff;
    font-weight: normal;
    font-size: .8rem;
}

.username {
    width: 15.7%;
}
.headerUsername {
    display: inline-block;
    width: 17.7%;
}

.email {
    width: 21.8%
}
.headerEmail {
    display: inline-block;
    width: 24%;
}

.name {
    width: 12%;
}
.headerName {
    display: inline-block;
    width: 16.7%;
}

.phone {
    width: 8%;
}
.headerPhone {
    display: inline-block;
    width: 8%;
}

.check {
    width: 5%;
    text-align: center;
}
.headerCheck {
    display: inline-block;
    text-align: center;
    width: 5.6%;
}

.headerCARBCertified {
    display: inline-block;
    width: 3%;
}

.footer {
    float: left;
}

.hidden {
    display: none;
}

#rolePopup, #groupPopup {
    overflow-y: hidden;
}

.usersBody {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden !important;
}

.rolesBody {
    height: 220px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden !important;
}

input[type=checkbox] {
    -ms-transform: scale(1.4); /* IE */
    -moz-transform: scale(1.4); /* FF */
    -webkit-transform: scale(1.4); /* Safari and Chrome */
    -o-transform: scale(1.4); /* Opera */
    transform: scale(1.4);
    padding: 10px;
}

.checkbox-header {
    width: 10%;
}

.button-div {
    padding: 1em;
}

.user-checkbox-label {
    margin-bottom: 0.5em;
}

.modal-title {
    background-color: lightgrey;
}

.wide-text-input {
    width: 95%;
}

.short-text-input {
    width: 50%
}

.tiny-text-input {
    width: 12%;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

#usersModal {
    font-size: .6rem;
}

.divActive {
    margin-left: 22px;
}

.addNewUser {
    vertical-align: middle;
    font-size: .9rem;
    text-align: right;
}

.addNewUser:hover {
    cursor: default;
    font-weight: 500;
}

.modalUserAdded {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 25;
    font-size: .8rem;
}

.deletedUser, .deletedGroup, .deletedRole {
    background-color : #ebd6d0;
}

.divUserTypeTitle {
    font-size: .7rem;
    font-weight: 500;
    margin-bottom: -8px;
}

.divShowDeletedUsers, .divUserType, .divSearch {
    font-size: .8rem;
    font-weight: 300;
    vertical-align: middle;
}

#txtSearch {
    width: 300px;
}

#divMessage {
    color: red;
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.p-paginator-prev {
    padding-right: .75rem;
}

.p-paginator-next {
    padding-left: .75rem;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 1rem;
    height: 2.357rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 3px;
}

.p-paginator-current, .p-paginator-first, .p-paginator-last, .p-paginator-next, .p-paginator-page, .p-paginator-prev {
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    position: relative;
}

.p-link {
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1.2rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px;
}

.p-disabled, .p-disabled * {
    cursor: default !important;
    pointer-events: none;
}

.p-component, .p-component * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.p-disabled, .p-component:disabled {
    opacity: 0.6;
}

.p-paginator-page:hover {
    background-color: #eceef1;
}
